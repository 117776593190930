import React, { useState } from 'react';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from '@mui/material'; // Import MUI 5 components
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import SpinningIcon from '@components/SpinningIcon';
import {
  useGetBannedTerms,
  useUpdateBannedTerm,
  useAddBannedTerm
} from '@hooks/useBannedTerms';
import {
  useGetAllowedTerms,
  useUpdateAllowedTerm,
  useAddAllowedTerm
} from '@hooks/useAllowedTerms';
import { Loading } from '@components/index';
import bannedTermTypes from '@utils/constants/bannedTermTypes';
import allowedTermTypes from '@utils/constants/allowedTermTypes';
import DeleteBannedTerm from '@pages/Sales/components/BannedOrAllowedTerms/components/DeleteBannedTerm';
import {
  BannedTermCreate,
  getExistingTermFromTerms,
  getTypeLabel
} from './utils';

const AddBannedTerm = ({
  isEmptyNewButton = false,
  newTermCreate = null,
  isBanned = true,
  blockingLabel = '',
  editingLabel = '',
  showLabel = false
}: {
  isEmptyNewButton?: boolean;
  newTermCreate?: BannedTermCreate | null;
  isBanned?: boolean;
  blockingLabel?: string;
  editingLabel?: string;
  showLabel?: boolean;
}) => {
  const { data: bannedTermsData, isLoading: isLoadingBannedTerms } =
    useGetBannedTerms();
  const { mutate: updateBannedTerm, isLoading: isUpdating } =
    useUpdateBannedTerm();
  const { mutate: addBannedTerm, isLoading: isAdding } = useAddBannedTerm();

  const { data: allowedTermsData } = useGetAllowedTerms();
  const { mutate: updateAllowedTerm } = useUpdateAllowedTerm();
  const { mutate: addAllowedTerm } = useAddAllowedTerm();

  const addFunc = isBanned ? addBannedTerm : addAllowedTerm;
  const updateFunc = isBanned ? updateBannedTerm : updateAllowedTerm;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const labelTermPastTense = isBanned ? 'Blocked' : 'Allowed';
  const labelTermFutureTense = isBanned ? 'Block' : 'Allow';

  const [newTerm, setNewTerm] = useState('');
  const [newType, setNewType] = useState('');
  const [isExact, setIsExact] = useState(false);

  const bannedTerms = bannedTermsData || [];
  const allowedTerms = allowedTermsData || [];
  const existingTerm = getExistingTermFromTerms(
    isBanned ? bannedTerms : allowedTerms,
    newTermCreate
  );
  const label = getTypeLabel(newTermCreate?.type || null, isBanned);

  const handleOpenModal = () => {
    if (newTermCreate) {
      setNewTerm(newTermCreate?.term);
      setNewType(newTermCreate?.type);
      setIsExact(newTermCreate?.exact || false);
    } else {
      setNewTerm('');
      setNewType('');
      setIsExact(false);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    const onSuccess = () => {
      // Reset the form and close the modal
      setNewTerm('');
      setNewType('');
      setIsExact(false);
      handleCloseModal();
    };

    if (existingTerm) {
      // Update the existing banned term
      updateFunc(
        {
          ...existingTerm,
          term: newTerm,
          type: newType,
          exact: isExact
        },
        { onSuccess }
      );
    } else {
      // Add a new banned term
      addFunc(
        {
          term: newTerm,
          type: newType,
          exact: isExact
        },
        { onSuccess }
      );
    }
  };

  const renderIcon = () => {
    if (isLoadingBannedTerms) {
      return <SpinningIcon isLoading />;
    }
    if (isEmptyNewButton) {
      return <AddIcon />;
    }
    if (existingTerm) {
      return <EditIcon />;
    }
    return isBanned ? <BlockIcon /> : <CheckIcon />;
  };

  const buttonDisabled = isAdding || isUpdating || isLoadingBannedTerms;

  const getTooltipText = () => {
    if (buttonDisabled) {
      return 'Please wait';
    }
    if (isEmptyNewButton) {
      return `Add ${label}`;
    }
    if (existingTerm) {
      return `Edit/Delete ${labelTermPastTense} ${label}`;
    }

    if (newTermCreate.term) {
      return `${labelTermFutureTense} ${label} ${newTermCreate.term}`;
    }
    return `${labelTermFutureTense} ${label}`;
  };

  const renderMainUI = () => {
    const termTypes = isBanned ? bannedTermTypes : allowedTermTypes;
    return (
      <Box>
        <TextField
          label="Term"
          value={newTerm}
          onChange={(e) => setNewTerm(e.target.value)}
          fullWidth
          sx={{ mt: 2 }}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Type</InputLabel>
          <Select value={newType} onChange={(e) => setNewType(e.target.value)}>
            {termTypes.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Exact</InputLabel>
          <Select
            value={isExact}
            onChange={(e) => setIsExact(e.target.value === 'true')}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>
        {existingTerm?.id ? (
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            marginTop="12px"
          >
            <DeleteBannedTerm
              isBanned={isBanned}
              callbackFn={handleCloseModal}
              id={existingTerm?.id}
            />
          </Box>
        ) : null}
      </Box>
    );
  };

  const renderActions = () => {
    return (
      <DialogActions>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isUpdating || isAdding}
        >
          {existingTerm
            ? `Update ${labelTermPastTense} ${label}`
            : `${labelTermFutureTense} ${label}`}
        </Button>
      </DialogActions>
    );
  };

  return (
    <Box display="flex">
      {showLabel && (
        <Box
          sx={{
            lineHeight: '40px !important'
          }}
        >
          {existingTerm ? editingLabel : blockingLabel}
        </Box>
      )}
      <Tooltip title={getTooltipText()}>
        <span>
          <IconButton
            color="primary"
            disabled={buttonDisabled}
            onClick={() => handleOpenModal()}
          >
            {renderIcon()}
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>
          {existingTerm
            ? `Update ${labelTermPastTense} `
            : `${labelTermFutureTense} `}{' '}
          {label}
        </DialogTitle>
        <DialogContent>
          {isUpdating || isAdding ? (
            <Loading minHeight="70px" margin="16px 0 0 0" />
          ) : (
            renderMainUI()
          )}
        </DialogContent>
        {renderActions()}
      </Dialog>
    </Box>
  );
};

export default AddBannedTerm;
